import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaArrowRight, FaPlus } from 'react-icons/fa';
import bg2 from "../assets/bg2.jpg";
import bg3 from "../assets/bg3.jpg";

const Services = () => {
  const images = [bg2, bg3];
  const [bgImage, setBgImage] = useState(images[0]);
  const [bgIndex, setBgIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % images.length;
        setBgImage(images[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div className="text-center jost">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#1E2019] bg-opacity-50 flex items-center justify-center flex-col">
          <motion.h1
            className="text-white text-4xl font-bold mb-4"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            Our Services
          </motion.h1>
        </div>
      </motion.div>

      <div className="bg-[#1E2019] py-10 px-4 text-center">
        <h2 className="text-[#335e88] text-3xl font-bold mb-4">
          We Make Your Home Shine!
        </h2>

        <div className="flex flex-col md:flex-row justify-around items-center text-white">
          <ServiceProcess number="1" title="Call or Text Us" />
          <FaArrowRight className="text-[#335e88] text-3xl mx-4 hidden md:block" />
          <ServiceProcess number="2" title="Get a Free Quote" />
          <FaArrowRight className="text-[#335e88] text-3xl mx-4 hidden md:block" />
          <ServiceProcess number="3" title="Let Us Work our Magic!" />
        </div>
      </div>

      <motion.div
        className="max-w-4xl mx-auto text-335e88 px-4 pt-4 pb-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-4 text-white">
          <ServiceCard title="Residential Cleaning" backgroundImage={bg2} />
          <ServiceCard title="Commercial Cleaning" backgroundImage={bg3} />
          <ServiceCard title="Move In/Out Cleaning" backgroundImage={bg2} />
          <ServiceCard title="Deep Cleaning" backgroundImage={bg3} />
          <ServiceCard title="Basic Cleaning" backgroundImage={bg2} />
        </div>

        <Accordion title="Residential Cleaning" details={standardCleaning} />
        <Accordion title="Deep Cleaning" details={deepCleaning} />
        <Accordion title="Move In/Out Cleaning" details={moveInOutCleaning} />
        <Accordion title="Remodeling Cleaning" details={remodelingCleaning} />
        <Accordion title="Commercial Cleaning" details={commercialCleaning} />
      </motion.div>
    </div>
  );
};

const ServiceProcess = ({ number, title }) => (
  <motion.div
    className="mb-4 md:mb-0 flex flex-col items-center"
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4 }}
  >
    <div className="bg-[#335e88] p-4 rounded-full text-2xl mb-2">{number}</div>
    <h3 className="text-xl font-semibold mb-1">{title}</h3>
  </motion.div>
);

const ServiceCard = ({ title, backgroundImage }) => {
  const cardStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <motion.div
      className="bg-335e88 bg-opacity-25 rounded-md p-4 flex flex-col items-center justify-center text-center hover:bg-opacity-50 transition duration-300 h-40 w-full"
      style={cardStyle}
      whileHover={{ scale: 1.05 }}
    >
      <h2 className="text-3xl font-semibold mb-2">{title}</h2>
    </motion.div>
  );
};

const Accordion = ({ title, details }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div className="mt-4 bg-[#335e88] text-white rounded-md p-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex justify-between items-center font-semibold text-lg text-white"
      >
        {title} <FaPlus className="text-white" />
      </button>
      {isOpen && (
        <motion.div
          className="mt-2 bg-[#1E2019] p-4 rounded-md"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <h4 className="text-lg font-bold mb-2 text-[#F2F4F7]">Includes:</h4>
          <ul className="list-disc list-inside text-[#F2F4F7]">
            {details.map((item, index) => (
              <li key={index} className="py-1">{item}</li>
            ))}
          </ul>
        </motion.div>
      )}
    </motion.div>
  );
};

const standardCleaning = [
  "Bathroom: Scrub tub/shower, Clean Toilet, Clean mirrors, Dust Surfaces, Vacuum and mop floors, Empty trash.",
  "Kitchen: Wipe countertops, Clean Stovetop, Clean microwave, Clean sink, Vacuum and mop floors, Empty trash.",
  "All rooms: Vacuum Carpets, Dust Furniture, Dust Electronics, Clean Mirrors, Vacuum Couch, Clean/Mop Floors, Empty trash."
];

const deepCleaning = [
  "Standard + Light Fixtures, Cobweb Removal, Baseboards, Exterior Vanity, Dust Vents/Fans, Dust Windows Sills, Dust Blinds.",
  "Standard + Exterior Cabients, Cobweb Removal, Baseboards, Exterior Applicances, Dust Fans, Light Fixtures.",
  "Standard + Interior Windows, Cobweb Removal, Baseboards, Exterior Appliances, Dust Fans, Light Fixtures."
];

const moveInOutCleaning = [
  "Deep Cleaning",
  "Interior Appliance, Interior Cabinets, Wall",
  "Door, Door Frame, Interior Windows, Windows Rails"
];

const remodelingCleaning = [
  "Deep Cleaning",
  "Interior Cabinet, Interior Appliances, Floor Paint Removal",
  "Glue Removal, Wall, Vacuum Carpet, Inside Windows"
];

const commercialCleaning = [
  "Dust Surfaces",
  "Clean Restrooms",
  "Vacuum Floors",
  "Mop Floors",
];

export default Services;
