import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import bg1 from "../assets/bg1.jpg";
import bg2 from "../assets/bg2.jpg";
import bg3 from "../assets/bg3.jpg";
import landingbefore from "../assets/landingbefore.jpg";
import landingafter from "../assets/landingafter.jpg";
import beforelanding from "../assets/beforelanding.jpg";
import afterlanding from "../assets/afterlanding.jpg";
import ImageComparison from "react-image-comparison";
import { FaHouse } from "react-icons/fa6";
import img1 from "../assets/img1.jpg";
import img4 from "../assets/img4.jpg";
import img5 from "../assets/img5.jpg";
import img7 from "../assets/img7.jpg";
import img8 from "../assets/img8.jpg";
import img9 from "../assets/img9.jpg";
import img10 from "../assets/img10.jpg";
import img11 from "../assets/img11.jpg";
import img12 from "../assets/img12.jpg";

const bgimages = [bg1, bg2, bg3];
const galleryImages = [img1, img4, img5, img7, img8, img9, img10, img11, img12];

const Gallery = () => {
  const [bgImage, setBgImage] = useState(bgimages[0]);
  const [bgIndex, setBgIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % bgimages.length;
        setBgImage(bgimages[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const openImage = (index) => {
    setSelectedImage(index);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="flex flex-col min-h-screen jost">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#1E2019] bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-white text-4xl font-bold mb-4 px-6">
            Past Work
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <a
              href="tel:+12065046745"
              className="bg-[#1E2019] text-[#335e88] px-4 py-2 rounded font-bold"
            >
              <span>📞</span>
              <span>Call us at (206) 504-6745 to Book</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 py-8 flex-1">
        <div className="relative h-auto sm:py-1">
          <div className="flex flex-col sm:flex-row justify-center items-center w-full space-y-6 sm:space-y-0 sm:space-x-6 p-6 sm:p-20">
            <div className="flex flex-col items-center w-full sm:w-1/2">
              <ImageComparison
                leftImage={landingbefore}
                rightImage={landingafter}
              />
              <div className="flex flex-row items-center mt-4 space-x-2">
                <FaHouse className="text-[#1E2019]"></FaHouse>
                <span className="text-lg text-[#1E2019]">
                  Ferndale Lakehouse Before & After
                </span>
              </div>
            </div>

            <div className="flex flex-col items-center w-full sm:w-1/2">
              <ImageComparison
                leftImage={beforelanding}
                rightImage={afterlanding}
              />
              <div className="flex flex-row items-center mt-4 space-x-2">
                <FaHouse className="text-[#1E2019]"></FaHouse>
                <span className="text-lg text-[#1E2019]">
                  West Seattle Garage Before & After
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {galleryImages.map((image, index) => (
            <motion.div
              key={index}
              className="border"
              whileHover={{
                scale: 1.1,
                translateZ: 50,
                perspective: 1000,
                boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
                transition: { duration: 0.2 },
              }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              onClick={() => openImage(index)}
            >
              <img
                src={image}
                alt={`Gallery ${index}`}
                className="w-full h-96 object-cover"
              />
            </motion.div>
          ))}
        </div>
      </div>

      {selectedImage !== null && (
        <motion.div
          className="fixed inset-0 bg-[#1E2019] bg-opacity-75 flex items-center justify-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          onClick={closeImage}
        >
          <motion.img
            src={galleryImages[selectedImage]}
            alt={`Gallery ${selectedImage}`}
            className="max-w-full max-h-full px-4 sm:px-3"
            initial={{ y: "-100vh" }}
            animate={{ y: 0 }}
            transition={{ type: "spring", stiffness: 100, damping: 20 }}
          />
        </motion.div>
      )}
    </div>
  );
};

export default Gallery;
