import React from 'react';
import { FaEnvelope, FaFacebook } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="bg-[#335e88] text-white py-4 jost">
            <div className="container mx-auto px-4">
                <div className="flex flex-col sm:flex-row justify-between items-center">
                    <div className="text-center sm:text-left mb-4 sm:mb-0">
                        <p>&copy; 2024 Makle Cleaning Services</p>
                        <p>Phone: (206) 504-6745</p>
                    </div>
                    <div className="flex space-x-4">
                        <a href="mailto:Maklenny@gmail.com" className="hover:text-gray-200">
                            <FaEnvelope size={24} />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
